<template>
  <div>
    <div class="title-bar">
      <h1>{{pageTitle}}</h1>
    </div>
    <UserCertificationList />
  </div>
</template>

<script>
import UserCertificationList from "./components/UserCertificationList.vue"
import userDataAccess from '../../dataAccess/userDataAccess';

export default {
  name: 'certifications',
  components: {
    UserCertificationList
  },
  data(){
    return {
      user: { name: '' },
      userDAO: new userDataAccess()
    }
  },
  mounted: async function(){
    await this.initUser();
  },
  methods: {
    async initUser(){
      try{
        this.user = await this.userDAO.getAccount(this.queryUser)
      }catch{
        this.snackbarShown = true;
      }
    },
  },
  computed: {
    pageTitle(){
      if(this.user.name === ''){
        return "";
      }
      if(this.queryUser === undefined){
        return 'My Certifications';
      }
      return `${this.user.name}'s Certifications`;
    },
    queryUser(){
      return this.$route.query.userId;
    }
  }
}
</script>