<template>
  <v-card class="certification-list">
    <div class="certifications">
      <v-data-table 
        :headers="certificationHeaders" 
        :items="displayCertifications" 
        :footer-props="{ 'items-per-page-options': [{'text': 'All', 'value': -1}]}">
          
        <template v-slot:item.expirationDate="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-chip
                :color="getColor(item.expirationDate)"
                v-on="on"
                dark
              >
                {{ item.expirationDate }}
              </v-chip>
            </template>
            <span>{{ ExpirationDateTooltip(item.expirationDate) }}</span>
          </v-tooltip>
        </template>

        <template v-slot:no-data>
          <p v-if="!isLoading"><br/>No certifications yet</p>
          <v-progress-circular v-else class="certs-loading" :color="colors.PRIMARY" indeterminate :size="50" :width="3"></v-progress-circular>
        </template>
      </v-data-table>

      <v-snackbar
        v-model="snackbarShown"
        :timeout="snackbarTimeout"
        color="red"
      >Error loading certifications.</v-snackbar>
      
    </div>
  </v-card>
</template>

<script>
import colors from "@/colors";
import certificationDataAccess from "../../../dataAccess/certificationDataAccess";
import userDataAccess from "../../../dataAccess/userDataAccess";
import moment from 'moment';

export default {
  name: "usercertificationlist",
  data() {
    return {
      colors,
      certDAO: new certificationDataAccess(),
      userDAO: new userDataAccess(),
      user: '',
      isLoading: true,
      snackbarTimeout: 5000,
      snackbarShown: false,
      certificationHeaders: [
        {
          text: "Certification Name",
          value: "displayCertName"
        },
        {
          text: "Date",
          value: "dateValue"
        },
        {
          text: "Provider",
          value: "provider"
        },
        {
          text: "Type",
          value: "type"
        },
        {
          text: "Expiration",
          value: "expirationDate"
        },
      ],
      certifications: []
    };
  },
  mounted: async function(){
    await this.initUser();
    await this.initCertifications();
  },
  methods: {
    async initUser(){
      try{
        this.user = await this.userDAO.getAccount(this.queryUser)
      }catch{
        this.snackbarShown = true;
      }
    },
    async initCertifications(){
      try{
        this.certifications = await this.certDAO.getCertifications(this.user.email);
      } catch{
        this.isLoading = false;
        this.snackbarShown = true;
      }
      this.isLoading = false;
    },
    getColor (date) {
      if (date === "N/A") return 'light-gray'
      else if (moment(date).isBefore(moment(), "day")) return 'red'
      else if (moment(date).diff(moment(), "day") < 90)  return 'orange'
      else return 'green'
    },
    ExpirationDateTooltip(date) {
      if (date === "N/A") return "No Expiration Date"
      else if (moment(date).isBefore(moment(), "day")) return "Expired"
      else if (moment(date).diff(moment(), "day") <= 90) return "Close to Expiring. " + moment(date).diff(moment(), "day").toString() + " Days Left." 
      else return "Expires in " + moment(date).diff(moment(), "day").toString() + " Days." 
    },
  },
  computed: {
    displayCertifications() {
      return this.certifications.map(c => {
        let x = {
          displayCertName: `${c.certNumber} | ${c.certName}`,
          dateValue:  moment(c.date).format('YYYY-MM-DD'),
          provider: c.provider,
          type: c.type,
          expirationDate: c.expirationDate === "" ? "N/A": moment(c.expirationDate).format('YYYY-MM-DD')
        };
        return x;
      });
    },
    queryUser(){
      return this.$route.query.userId;
    }
  }
};
</script>

<style scoped>
.certification-list {
  max-width: 1200px;
  margin: 20px;
}

.certifications {
  margin-top: 5px;
}

.certs-loading{
  margin: 40px;
}
</style>